import keycloak from "@/plugins/keycloak";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  transition: "Vue-Toastification__bounce",
  newestOnTop: true,
  maxToasts: 3,
  position: "bottom-right",
};
Vue.use(Toast, options);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  keycloak,
  render: (h) => h(App),
}).$mount("#app");
