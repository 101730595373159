<template>
  <v-app>
    <v-main class="dark lighten-4">
      <ToolbarMenu ref="toolBar" />
      <div class="ma-4">
        <br />
        <router-view />
      </div>
    </v-main>

    <ConfirmDialog ref="confirm" />
  </v-app>
</template>

<style>
@import "./assets/styles/site.css";
</style>

<script>
import ToolbarMenu from "./components/ToolbarMenu";
import ConfirmDialog from "./components/ConfirmDialog";
export default {
  name: "App",
  components: {
    ToolbarMenu,
    ConfirmDialog,
  },
  data: () => ({}),
  mounted: function () {
    //this.callConfirmation();
  },
  methods: {
    async callConfirmation() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this record?"
        )
      ) {
        alert("You got response 'ok' do your stuff here");
      }
    },
  },
  watch: {},
};
</script>
