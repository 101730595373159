<template>
  <v-app-bar app ref="toolBar">
    <link rel="stylesheet" :href="themeLink" />
    <v-menu :close-on-content-click="closeMenu" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-3"
          color="primary"
          fab
          outlined
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>widgets</v-icon>
        </v-btn>
      </template>
      <v-list shaped max-height="90vh" class="overflow-y-auto">
        <v-subheader style="font-style: italic; font-weight: bold">
          Menu
        </v-subheader>
        <template v-for="(item, i) in menus">
          <template v-if="!item.children">
            <v-list-item
              :key="i"
              :to="item.path"
              color="primary"
              @click="onMenuClick(true, item)"
            >
              <v-list-item-icon>
                <v-icon :color="item.color" v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else-if="item.children">
            <v-list-group
              :key="i"
              :prepend-icon="item.icon"
              @click="onMenuClick(false)"
            >
              <template v-slot:activator>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </template>
              <template v-for="(child, c) in item.children">
                <v-list-item :key="c" :to="child.path">
                  <v-list-item-icon style="padding-left: 20px !important">
                    <v-icon :color="child.color" v-text="child.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content @click="onMenuClick(true, child)">
                    <v-list-item-title v-text="child.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </template>
      </v-list>
    </v-menu>
    <v-toolbar-title>
      <router-link to="/">
        <v-img
          style="border-radius: 4px; background-color: whitesmoke"
          :src="logoPath"
          href="/"
          class="my-3"
          contain
          max-height="40"
          max-width="100"
        />
      </router-link>
    </v-toolbar-title>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <v-spacer></v-spacer>
    <template v-if="userName">
      <span class="font-italic">
        Welcome, <b> {{ userName }}!</b>
      </span>
    </template>

    <v-tooltip bottom v-if="!isUserLoged()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="goToLoginView" v-on="on" v-bind="attrs">
          <v-icon color="primary"> mdi-login </v-icon>
        </v-btn>
      </template>
      <span>Login</span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="logout" v-on="on" v-bind="attrs">
          <v-icon color="primary"> mdi-logout </v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="toggleTheme()" v-on="on" v-bind="attrs">
          <v-icon v-if="!$vuetify.theme.dark" color="orange lighten-2"
            >mdi-lightbulb</v-icon
          >
          <v-icon v-if="$vuetify.theme.dark" color="grey darken-1"
            >mdi-lightbulb-outline</v-icon
          >
        </v-btn>
      </template>
      <span v-if="!$vuetify.theme.dark">Dark mode</span>
      <span v-if="$vuetify.theme.dark">Light mode</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import { clearBrowserStorage } from "@/utilities/global";
import Vue from "vue";

export default {
  name: "toolbar-menu",
  data: () => ({
    menus: [],
    items: [],
    closeMenu: true,
    logoPath: require("../assets/logo_big.png"),
    currentTheme: "material-main",
    userName: null,
  }),
  created: function () {
    if (localStorage.getItem("isDarkMode") == "true") {
      this.toggleTheme();
    }
    this.$router.options.routes.forEach((route) => {
      if (route.isVisible) {
        if (route.children !== undefined) {
          //Stefan - change this code because old code is not work if children more than one.
          const isHidden = route.children.some((r) => r.isVisible == false);
          if (isHidden) {
            route.children = route.children.filter((r) => r.isVisible != false);
          }
        }
        this.menus.push(route);
      }
    });

    this.userName = sessionStorage.getItem("userName")
      ? sessionStorage.getItem("userName")
      : localStorage.getItem("userName");
  },
  watch: {
    $route(toLink) {
      this.setupBreadCrumbs(toLink);
    },
  },
  methods: {
    goToLoginView() {
      // if (this.$router.currentRoute.fullPath != "/LoginView") {
      //   this.$router.push({ name: "Login" });
      // }
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (this.$vuetify.theme.dark) {
        this.currentTheme = "material-main-dark";
        localStorage.setItem("isDarkMode", true);
      } else if (this.$vuetify.theme.dark == false) {
        this.currentTheme = "material-main";
        localStorage.setItem("isDarkMode", false);
      }
    },
    onMenuClick(closeMenu, item) {
      if (item != undefined) {
        sessionStorage.setItem("redirectLink", item.path);
      }
      if (closeMenu) {
        this.closeMenu = true;
      } else {
        this.closeMenu = false;
      }
    },
    setupBreadCrumbs(toLink) {
      this.items = [];
      let allRoutes = this.$router.options.routes;
      allRoutes.forEach((route) => {
        if (route.children) {
          let child = route.children.find((x) => x.name == toLink.name);
          if (child != undefined) {
            this.items.push({
              text: child.parent,
              disabled: false,
              href: "#" + child.path,
            });
          }
        }
      });
      this.items.push({
        text: toLink.name,
        disabled: false,
        href: "#" + toLink.path,
      });

      //Add Bread Crumbs for routes that not show in menu. (isVisible: false)
      if (this.items.length == 1) {
        const paramLength = Object.keys(
          this.$router.history.current.params
        ).length;

        if (paramLength > 0) {
          this.items.unshift({
            text: this.$route.matched[0].name,
            disabled: false,
            href: "#" + this.$router.history.current.fullPath,
          });
        }
      }
    },
    onThemeChange(value) {
      this.currentTheme = value;
    },
    logout() {
      clearBrowserStorage();
      this.userName = null;
      Vue.$keycloak.logout();
    },
    isUserLoged() {
      const isUserLogged =
        sessionStorage.getItem("bearerToken") ||
        localStorage.getItem("bearerToken");
      if (isUserLogged != null) {
        return true;
      }

      return false;
    },
  },
  computed: {
    themeLink() {
      return (
        "https://kendo.cdn.telerik.com/themes/5.8.0/material/" +
        this.currentTheme +
        ".css"
      );
    },
  },
  mounted() {
    window.addEventListener("username-session-storage-changed", (event) => {
      this.userName = event.detail.storage;
    });
  },
};
</script>
