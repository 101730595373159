import axios from "axios";
import config from "@/config";
import vue from "vue";

import ToastComponent from "@/components/ToastComponent.vue";

let currentRequestsCounter = 0;

// create an axios instance
const request = axios.create({
  baseURL: config.baseURL,
  timeout: config.requestTimeout * 1000, // request timeout
});

// request interceptor
request.interceptors.request.use(
  (config) => {
    currentRequestsCounter++;
    if (sessionStorage.getItem("bearerToken")) {
      config.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
        "bearerToken"
      )}`;
    } else if (localStorage.getItem("bearerToken")) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "bearerToken"
      )}`;
    }
    return config;
  },
  (error) => {
    // console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
request.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      /// If not an OperationalResult
      if (response.data.resultData === undefined) {
        return response.data;
      }

      // if the code is different from 200,201,204 show error message.
      switch (response.data.type) {
        case 1: //SUCCESS
          if (response.data.message) {
            vue.$toast.success({
              component: ToastComponent,
              props: {
                messageTitle: response.data.message,
                messageText: response.data.additionalMessages,
              },
            });
          }
          return response.data.resultData;
        case 0: //ERROR
          vue.$toast.error({
            component: ToastComponent,
            props: {
              messageTitle: response.data.message || "An error occured",
              messageText: response.data.additionalMessages,
            },
          });
          return Promise.reject(new Error("Error type"));
        case -1: //EXCEPTION
          vue.$toast.warning({
            component: ToastComponent,
            props: {
              messageTitle:
                response.data.message ||
                "An exception occured. Please see exception logs",
              messageText: response.data.additionalMessages,
            },
          });
          return Promise.reject(new Error("Exception type"));
      }
    }
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      sessionStorage.clear();
      localStorage.clear();
      location.reload();
      vue.$toast.error({
        component: ToastComponent,
        props: {
          messageTitle: error.response.data,
        },
      });
    } else {
      console.error(error);
      vue.$toast.error({
        component: ToastComponent,
        props: {
          messageTitle: error.message || "Error",
          messageText: error.response.data,
        },
      });
    }
    return Promise.reject(error);
  }
);

export default request;
