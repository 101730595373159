import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/WhmcsControl",
    name: "Whmcs Control",
    component: () => import("../views/WhmcsControlView.vue"),
    icon: "menu_book",
    color: "default",
    meta: {
      requiresAuth: true,
    },
    isVisible: true,
  },
  {
    path: "/Invoices",
    name: "Invoices",
    component: () =>
      import("../views/RouterChildComponents/InvoicesChildComponentRouter.vue"),
    icon: "mdi-file-document-multiple",
    isVisible: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        parent: "Invoices",
        path: "/Invoices/InvoiceTable",
        name: "Invoice Table",
        component: () => import("../views/Invoices/InvoiceTableView.vue"),
        icon: "mdi-file-table-box-multiple-outline",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Invoices",
        path: "/Invoices/InvoiceLineItems",
        name: "Invoice Line Items",
        component: () => import("../views/Invoices/InvoiceLineItemView.vue"),
        icon: "mdi-file-chart-outline",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
    ],
  },
  {
    path: "/ActivityControl",
    name: "Activity Control",
    component: () => import("../views/ActivityControlView.vue"),
    icon: "pending_actions",
    color: "default",
    meta: {
      requiresAuth: true,
    },
    isVisible: true,
  },
  {
    path: "/UpdateData",
    name: "Update Data",
    component: () =>
      import(
        "../views/RouterChildComponents/UpdateDataChildComponentRouter.vue"
      ),
    icon: "sync",
    isVisible: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        parent: "Update Data",
        path: "/UpdateData/WhmcsData",
        name: "WHMCS Data",
        component: () => import("../views/UpdateData/WhmcsDataView.vue"),
        icon: "mdi-database-settings",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Update Data",
        path: "/UpdateData/AbacusActivities",
        name: "PROLES Activities",
        component: () => import("../views/UpdateData/AbacusActivitiesView.vue"),
        icon: "mdi-network-pos",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Update Data",
        path: "/UpdateData/AbacusCreditors",
        name: "Creditor Costs",
        component: () => import("../views/UpdateData/AbacusCreditorsView.vue"),
        icon: "account_balance_wallet",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Update Data",
        path: "/UpdateData/AbacusAccounts",
        name: "ABACUS Accounts",
        component: () => import("../views/UpdateData/AbacusAccountsView.vue"),
        icon: "mdi-account-card",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
    ],
  },
  {
    path: "/Metadata",
    name: "Metadata",
    component: () =>
      import("../views/RouterChildComponents/MetadataChildComponentRouter.vue"), // this is router component which renders child components
    icon: "account_tree",
    isVisible: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        parent: "Metadata",
        path: "/Metadata/Accounts",
        name: "Accounts",
        component: () => import("../views/Metadata/AccountsView.vue"),
        icon: "account_balance_wallet",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Metadata",
        path: "/Metadata/Suppliers",
        name: "Suppliers",
        component: () => import("../views/Metadata/SuplliersView.vue"),
        icon: "engineering",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Metadata",
        path: "/Metadata/Clients",
        name: "Clients",
        component: () => import("../views/Metadata/ClientsView.vue"),
        icon: "contacts",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Metadata",
        path: "/Metadata/CreditorCosts",
        name: "CreditorCosts",
        component: () => import("../views/Metadata/CreditorCostsView.vue"),
        icon: "mdi-cash-multiple",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Metadata",
        path: "/Metadata/Products",
        name: "Products",
        component: () => import("../views/Metadata/ProductsView.vue"),
        icon: "store",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Metadata",
        path: "/Metadata/ActivityTypes",
        name: "Activity Types",
        component: () => import("../views/Metadata/ActivityTypesView.vue"),
        icon: "build",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Metadata",
        path: "/Metadata/Projects",
        name: "Projects",
        component: () => import("../views/Metadata/ProjectsView.vue"),
        icon: "work",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Metadata",
        path: "/Metadata/Services",
        name: "Services",
        component: () => import("../views/Metadata/ServicesView.vue"),
        icon: "mdi-server-network",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "Metadata",
        path: "/Metadata/affiliateGroups",
        name: "Affiliate Groups",
        component: () => import("../views/Metadata/AffiliateGroupsView.vue"),
        icon: "mdi-home-group",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
    ],
  },
  {
    path: "/Reports",
    name: "Reports",
    component: () =>
      import("../views/RouterChildComponents/ReportsChildComponentRouter.vue"), // this is router component which renders child components
    icon: "mdi-chart-tree",
    isVisible: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      // {
      //   parent: "Reports",
      //   path: "/Reports/IncomeAndCostOfActivities",
      //   name: "Income And Cost Of Activities",
      //   component: () =>
      //     import("../views/Reports/IncomeAndCostOfActivitiesView.vue"),
      //   icon: "mdi-chart-bar-stacked",
      //   color: "default",
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   isVisible: true,
      // },
      {
        parent: "Reports",
        path: "/Reports/ClientMetrics",
        name: "Client Metrics",
        component: () => import("../views/Reports/ClientMetricsView.vue"),
        icon: "mdi-home-analytics",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
    ],
  },
  {
    path: "/AffiliatePayment",
    name: "Affiliate Payment",
    component: () =>
      import(
        "../views/RouterChildComponents/AffiliatePaymentChildComponentRouter.vue"
      ), // this is router component which renders child components
    icon: "mdi-account-credit-card",
    isVisible: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        parent: "AffiliatePayment",
        path: "/AffiliatePayment/PendingPayments",
        name: "Pending Payments",
        component: () =>
          import("../views/AffiliatePayment/PendingPaymentsView.vue"),
        icon: "mdi-cash-clock",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "AffiliatePayment",
        path: "/AffiliatePayment/PerformedPayments",
        name: "Performed Payments",
        component: () =>
          import("../views/AffiliatePayment/PerformedPaymentsView.vue"),
        icon: "mdi-credit-card-marker",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "AffiliatePayment",
        path: "/AffiliatePayment/PaymentForDate/:id",
        name: "Payment for date",
        component: () => import("../views/AffiliatePayment/PaymentForDate.vue"),
        icon: "mdi-credit-card-marker",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: false,
      },
      {
        parent: "AffiliatePayment",
        path: "/AffiliatePayment/PaymentsReleaseDate/:affiliateId",
        name: "Payments release date",
        component: () =>
          import("../views/AffiliatePayment/PaymentsReleaseDateView.vue"),
        icon: "mdi-credit-card-marker",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: false,
      },
    ],
  },
  {
    path: "/CNLDocGenerator",
    name: "CNL Doc Generator",
    component: () =>
      import(
        "../views/RouterChildComponents/CNLDocGeneratorChildComponentRouter.vue"
      ), // this is router component which renders child components
    icon: "mdi-file-document-plus-outline",
    isVisible: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        parent: "CNLDocGenerator",
        path: "/CNLDocGenerator/InvoiceControl",
        name: "Invoice Control",
        component: () =>
          import("../views/CNLDocGenerator/InvoiceControl.vue"),
        icon: "mdi-file-document-arrow-right-outline",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "CNLDocGenerator",
        path: "/CNLDocGenerator/SupplierCNLDocuments",
        name: "CNL Documents Supplier",
        component: () =>
          import("../views/CNLDocGenerator/GeneratedCNLSupplierDocumentsView.vue"),
        icon: "mdi-file-document-check-outline",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "CNLDocGenerator",
        path: "/CNLDocGenerator/ClientsCNLDocuments",
        name: "CNL Documents Clients",
        component: () =>
          import("../views/CNLDocGenerator/GeneratedCNLClientsDocumentsView.vue"),
        icon: "mdi-file-document-check-outline",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
      {
        parent: "CNLDocGenerator",
        path: "/CNLDocGenerator/CnlDocumentDetails/:id",
        name: "CNL Document Details",
        component: () =>
          import("../views/CNLDocGenerator/CnlDocumentDetailsView.vue"),
        icon: "mdi-home-analytics",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: false,
      },
    ],
  },
  {
    path: "/Account",
    name: "Account",
    component: () =>
      import("../views/RouterChildComponents/AccountChildComponentRouter.vue"), // this is router component which renders child components
    icon: "mdi-account-cog",
    meta: {
      requiresAuth: true,
    },
    isVisible: true,
    children: [
      {
        parent: "Account",
        path: "/Account/Register",
        name: "Register",
        component: () => import("../views/Account/RegisterView.vue"),
        icon: "mdi-account-plus",
        color: "default",
        meta: {
          requiresAuth: true,
        },
        isVisible: true,
      },
    ],
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import("../views/Home.vue"), // this is router component which renders child components
    icon: "mdi-account-cog",
    meta: {
      requiresAuth: true,
    },
    isVisible: false,
  },
  //removed due to keycloak login/logout
  // {
  //   path: "/LoginView",
  //   name: "Login",
  //   component: () => import("../views/Account/LoginView.vue"),
  //   isVisible: false,
  // },
];

const router = new VueRouter({
  // mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("matched");
    next();
  } else {
    console.log("not matched");
    next({ name: "Home" });
  }
});

export default router;
