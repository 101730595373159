import Vue from "vue";
import Keycloak from "keycloak-js";

const options = {
  //DEV
  // url: "https://padcom-keycloak.smartsystems.bg/",
  // realm: "master",
  // clientId: "padcom-pcs-frontend-dev",
  // onLoad: "login-required",
  // checkLoginIframe: false,
  // responseMode: "query",
  // silentCheckSsoRedirectUri: window.location.origin,

  //TEST
  // url: "https://padcom-keycloak.smartsystems.bg/",
  // realm: "master",
  // clientId: "padcom-pcs-frontend-test",
  // onLoad: "login-required",
  // checkLoginIframe: false,
  // responseMode: "query",
  // silentCheckSsoRedirectUri: window.location.origin,

  //PROD
  url: "https://keycloak.padcom.ch/",
  realm: "master",
  clientId: "padcom-pcs-frontend",
  onLoad: "login-required",
  checkLoginIframe: false,
  responseMode: "query",
  silentCheckSsoRedirectUri: window.location.origin,
};

let _keycloak = new Keycloak(options);

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak;
  },
};

Plugin.install = (Vue) => {
  Vue.$keycloak = _keycloak;
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak;
      },
    },
  });
};

Vue.use(Plugin);

Vue.$keycloak
  .init(options)
  .then((auth) => {
    console.log(Vue.$keycloak);
    if (!auth) {
      window.location.reload();
      console.log("Not Authenticated");
    } else {
      console.log("Authenticated");
    }

    if (
      Vue.$keycloak.token &&
      Vue.$keycloak.idToken &&
      Vue.$keycloak.token != "" &&
      Vue.$keycloak.idToken != ""
    ) {
      Vue.$keycloak.loadUserInfo().then((res) => {
        sessionStorage.setItem("bearerToken", Vue.$keycloak.token);
        sessionStorage.setItem("userName", res.name);
        window.dispatchEvent(
          new CustomEvent("username-session-storage-changed", {
            detail: {
              storage: sessionStorage.getItem("userName"),
            },
          })
        );
      });
    }
  })
  .catch((e) => {
    console.log("Authentication Failed", e);
  });

export default Plugin;
