<template>
  <div>
    <h4>{{ messageTitle }}</h4>
    <span v-if="messageText">
      <span v-if="Array.isArray(messageText)">
        <span v-for="(msg, i) in messageText" :key="i">
          <span>{{ msg }} <br /></span>
        </span>
      </span>
      <span v-else> {{ messageText }}</span>
    </span>
  </div>
</template>
<script>
export default {
  name: "ToastComponent",
  props: ["messageTitle", "messageText"],
};
</script>
