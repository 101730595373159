import moment from "moment";
import $ from "jquery";
import ToastComponent from "@/components/ToastComponent.vue";
import request from "@/utilities/request";
import config from "@/config";
import axios from "axios";

export function filterQuery(query) {
  Object.keys(query).forEach((key) => {
    if (query[key] === "") {
      delete query[key];
    }
  });
  return query;
}

export function formatDateOnly(date) {
  if (date != null) {
    return moment(date).format("YYYY-MM-DD");
  } else {
    return "-";
  }
}

export function formatDecimal(decimal) {
  if (decimal != null) {    
    return decimal.toLocaleString("de-DE", {minimumFractionDigits: 2});
  } else {
    return "-";
  }
}

export function formatDate(date) {
  if (date != null) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "-";
  }
}

export function accountsCustomFilter(item, queryText) {
  if (!isNaN(queryText)) {
    return item.number.toString().startsWith(queryText.toString());
  }
  return (
    (item.currency.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
      -1) |
    (item.description
      .toLocaleLowerCase()
      .indexOf(queryText.toLocaleLowerCase()) >
      -1) |
    (item.number == queryText)
  );
}

export function filterRespCompany(item, queryText) {
  return (
    (item.companyname &&
      item.companyname
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
    (item.defaultContact?.fullName &&
      item.defaultContact?.fullName
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1)
  );
}

export function filterRespPerson(item, queryText) {
  return (
    (item.abbreviation &&
      item.abbreviation
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
    (item.fullName &&
      item.fullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1)
  );
}

export function rearangeChipsInDropdown(headers, vue) {
  let customHeaders = [];
  headers.forEach((header) => {
    if (header.title != "") {
      customHeaders.push(header);
    }
  });

  vue.dropdownCustomHeaders = customHeaders;
}

// used to get specific field data if custom templated is used in kendo grid
export function getNestedValue(fieldName, dataItem) {
  const path = fieldName.split(".");
  let data = dataItem;
  path.forEach((p) => {
    data = data ? data[p] : undefined;
  });
  return data;
}

export function getColumnWidth(tableName, col, vue) {
  let columnWidth;
  $("#" + tableName + " th").each(function () {
    if ($(this).text().trim() == col.title) {
      columnWidth = $(this).width();
    }
  });

  let variantColumn = vue.variantColumns.find((vc) => vc.title == col.title);
  if (columnWidth !== undefined) {
    return { columnWidth: columnWidth + "px", variantColumn: variantColumn };
  }
}

export const eGridNames = {
  WHMCSControl: 1,
  ActivityControl: 2,
  InvoiceControl: 3,
  InvoiceLineControl: 4,
};

function isContaining(obj, key, columns, searchString) {
  return (
    obj[key] &&
    columns.some((c) => c.toLowerCase().includes(key.toLowerCase())) &&
    obj[key].toString().toLowerCase().includes(searchString.toLowerCase())
  );
}

//e = event. vue = this. columns = the columns to filter by.
export function onSearchFilterChange(e, vue, columns, gridDetailsProp) {
  //freshly fill gridData with all available data
  vue.gridData = JSON.parse(JSON.stringify(vue.allData));

  //if filter is empty return all data
  if (e.type == "click" || vue.searchString == "") {
    vue.searchString = "";
    vue.total = vue.gridData.length;
    return;
  }

  //itterate all data to find matches
  vue.allData.forEach((ad) => {
    let found = false;
    let foundInDetails = false;

    //search in props
    if (!found) {
      for (const key in ad) {
        if (typeof ad[key] === "object") {
          for (const key2 in ad[key]) {
            if (isContaining(ad[key], key2, columns, vue.searchString)) {
              found = true;
              break;
            }
          }
        }

        if (!found && isContaining(ad, key, columns, vue.searchString)) {
          found = true;
          break;
        }
      }
    }

    //search in child props
    if (!found && gridDetailsProp && ad[gridDetailsProp].length > 0) {
      ad[gridDetailsProp].forEach((gdp) => {
        for (const key in gdp) {
          if (isContaining(gdp, key, columns, vue.searchString)) {
            vue.gridData.find((x) => x.id === ad.id).showGridDetails = true;
            found = true;
            foundInDetails = true;
            break;
          }
        }
      });
    }

    //if not found, remove this row from gridData
    if (!found) {
      let ix = vue.gridData.findIndex((d) => d.id == ad.id);
      vue.gridData.splice(ix, 1);
    }
  });

  vue.total = vue.gridData.length;

  //go to first page if we are on a page number greater than last available after filtering
  if (vue.skip > vue.total) vue.skip = 0;
}

export function clearBrowserStorage() {
  localStorage.removeItem("bearerToken");
  localStorage.removeItem("userName");
  localStorage.removeItem("redirectLink");
  localStorage.removeItem("isDarkMode");

  sessionStorage.removeItem("bearerToken");
  sessionStorage.removeItem("userName");
  sessionStorage.removeItem("redirectLink");
}

export function getCompanies(vue) {
  vue.gridData.forEach((dataObj) => {
    const company = {
      name: dataObj.linkedToEntity.companyname,
      id: dataObj.linkedToEntity.id,
    };

    vue.companies.push(company);
  });

  vue.companies = [
    ...new Map(vue.companies.map((item) => [item.name, item])).values(),
  ]; // Distinct companies by name
  vue.companies.sort((a, b) => (a.name > b.name ? 1 : -1));
}

export function onCompanySelected(vue, companyId) {
  vue.gridData = vue.gridData.filter((e) => e.linkedToEntity.id === companyId);
}

export function submitFilesForUpdateData(vue, endpoint) {
  vue.loadingActivities = true;

  if (vue.files.length == 0) {
    this.$toast.warning({
      component: ToastComponent,
      props: {
        messageTitle: "Message",
        messageText: "No files selected",
      },
    });
    return;
  }

  let formData = new FormData();
  formData.append("file", vue.files);
  formData.append("saveData", vue.importData);

  const request = axios.create({
    baseURL: config.baseURL,
    timeout: config.requestTimeout * 60000,
  });

  // request interceptor
  request.interceptors.request.use((config) => {
    if (sessionStorage.getItem("bearerToken")) {
      config.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
        "bearerToken"
      )}`;
    } else if (localStorage.getItem("bearerToken")) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "bearerToken"
      )}`;
    }
    return config;
  });

  request
    .post(`/api/UpdateData/${endpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      // if the code is different from 200,201,204 show error message.
      if (response.status === 200) {
        switch (response.data.type) {
          case 1: //SUCCESS
            if (response.data.message) {
              vue.$toast.success({
                component: ToastComponent,
                props: {
                  messageTitle: response.data.message,
                  messageText: response.data.additionalMessages,
                },
              });
            }
            return response.data.resultData;
          case 0: //ERROR
            vue.$toast.error({
              component: ToastComponent,
              props: {
                messageTitle: response.data.message || "An error occured",
                messageText: response.data.additionalMessages,
              },
            });
            return Promise.reject(new Error("Error type"));
          case -1: //EXCEPTION
            vue.$toast.warning({
              component: ToastComponent,
              props: {
                messageTitle:
                  response.data.message ||
                  "An exception occured. Please see exception logs",
                messageText: response.data.additionalMessages,
              },
            });
            return Promise.reject(new Error("Exception type"));
        }
      }
    })
    .catch((error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        sessionStorage.clear();
        localStorage.clear();
        location.reload();
        vue.$toast.error({
          component: ToastComponent,
          props: {
            messageTitle: "Session Expired",
          },
        });
      } else {
        console.error(error);
        vue.$toast.error({
          component: ToastComponent,
          props: {
            messageTitle: error.message || "Error",
            messageText: error.response.data,
          },
        });
      }
      return Promise.reject(error);
    })
    .finally(() => {
      vue.loadingActivities = false;
    });
}

export function exportDocumentToFile(
  endpoint,
  documentName,
  fileType,
  data,
  noDate
) {
  let dateInfo = "";
  if (!noDate) {
    dateInfo = new Date(Date.now()).toLocaleDateString().replaceAll("/", "_");
  }

  return request({
    url: endpoint,
    method: "POST",
    responseType: "blob",
    data: data && data,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${documentName}` + dateInfo + `.${fileType}`
      ); //TODO get filename from server
      document.body.appendChild(link);
      link.click();
    })
    .catch(() => {});
}

export function getPdfFromDb(vue, importType) {
  vue.loadingFile = true;
  return request({
    url: "api/UpdateData/GetGuideline",
    method: "GET",
    params: { importType: importType },
    responseType: "arraybuffer",
  })
    .then((data) => {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/pdf",
        })
      );
      window.open(url, "_blank");
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      vue.loadingFile = false;
    });
}
